<div class="container">
  <div class="row text-nowrap">
    <div class="col">
      <!--<h1>RingCentral</h1>
      <div class="row">
        <div class="col-4 ">
          <div class="card text-center">
            <div class="card-header">
              Jour
            </div>
            <div class="card-body">
              {{ringCentralStatsCounter.inboundCall.day}} <br>
              {{ringCentralStatsCounter.outboundCall.day}}
            </div>
            <div class="card-footer text-muted">
              {{(ringCentralStatsCounter.inboundCall.day + ringCentralStatsCounter.outboundCall.day) - (ringCentralStatsCounter.outboundCall.lastDay + ringCentralStatsCounter.outboundCall.lastDay)}}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card text-center">
            <div class="card-header">
              Semaine
            </div>
            <div class="card-body">
              {{ringCentralStatsCounter.inboundCall.week}} <br>
              {{ringCentralStatsCounter.outboundCall.week}}
            </div>
            <div class="card-footer text-muted">
              {{(ringCentralStatsCounter.inboundCall.week + ringCentralStatsCounter.outboundCall.week) - (ringCentralStatsCounter.outboundCall.lastWeek + ringCentralStatsCounter.outboundCall.lastWeek)}}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card text-center">
            <div class="card-header">
              Mois
            </div>
            <div class="card-body">
              {{ringCentralStatsCounter.inboundCall.month}} <br>
              {{ringCentralStatsCounter.outboundCall.month}}
            </div>
            <div class="card-footer text-muted">
              {{(ringCentralStatsCounter.inboundCall.month + ringCentralStatsCounter.outboundCall.month) - (ringCentralStatsCounter.outboundCall.lastMonth + ringCentralStatsCounter.outboundCall.lastMonth)}}
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col">

          <h2>Statut des hotliners</h2>
          <div class="table-responsive">
            <table class="table table-hover table-dark table-striped">
              <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Statut de présence défini</th>
                <th scope="col">Statut dérangement</th>
                <th scope="col">Statut téléphonique</th>
                <th scope="col">Depuis</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let hotliner of this.hotlinerService.getHotlinersRingCentral()">
                <th
                  scope="row">{{ hotliner.hotlinerInfo.firstName }} {{ hotliner.hotlinerInfo.lastName }}</th>

                <td>
                  <div class="row nav-justify-content-center">
                    <div class="nav-item col-1" *ngIf="hotliner.hotlinerStatus.definedStatus == 'Occupé';"
                         style="background-color: red;border-radius: 100px;"></div>
                    <div class="nav-item col-1" *ngIf="hotliner.hotlinerStatus.definedStatus == 'Disponible';"
                         style="background-color: green;border-radius: 100px;"></div>
                    <div class="nav-item col-1" *ngIf="hotliner.hotlinerStatus.definedStatus == 'Déconnecté';"
                         style="background-color: white;border-radius: 100px;"></div>
                    <div class="nav-item col-auto">{{hotliner.hotlinerStatus.definedStatus}}</div>
                  </div>
                </td>
                <td>
                  <div class="row nav-justify-content-center">
                    <div class="nav-item col-1" *ngIf="hotliner.hotlinerStatus.dndStatus == 'Ne prends aucun appel';"
                         style="background-color: red; border-radius: 100px;"></div>
                    <div class="nav-item col-1" *ngIf="hotliner.hotlinerStatus.dndStatus == 'Prends tous les appels';"
                         style="background-color: green; border-radius: 100px;"></div>
                    <div class="nav-item col-auto">{{ hotliner.hotlinerStatus.dndStatus }}</div>
                  </div>
                </td>
                <td>
                  <ng-container
                    *ngIf="hotliner.hotlinerStatus.telephonyStatus == 'Pas d\'appel'; else elseActiveCall"></ng-container>
                  <ng-template #elseActiveCall>
                    <ng-container *ngFor="let hotlinerActiveCallItem of hotliner.hotlinerStatus.hotlinerActiveCalls">
                      <ng-container *ngIf="hotlinerActiveCallItem.direction === 'Outbound'; else elseImgCall">
                        <img src="../../assets/ressources/outbound.png" alt="outbound.png"/>
                        {{hotlinerActiveCallItem.to}}
                      </ng-container>
                      <ng-template #elseImgCall>
                        <img src="../../assets/ressources/inbound.png" alt="inbound.png"/>
                        {{hotlinerActiveCallItem.from}}
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </td>
                <td>{{ hotliner.lastTimeUpdated }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div class="col">
      <!--<h1>Freshdesk</h1>
      <div class="row">
        <div class="col-4">
          <div class="card text-center">
            <div class="card-header">
              Jour
            </div>
            <div class="card-body">
              {{freshDeskStatsCounter.day}}
            </div>
            <div class="card-footer text-muted">
              {{freshDeskStatsCounter.lastDay}}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card text-center">
            <div class="card-header">
              Semaine
            </div>
            <div class="card-body">
              {{freshDeskStatsCounter.week}}
            </div>
            <div class="card-footer text-muted">
              {{freshDeskStatsCounter.lastWeek}}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card text-center">
            <div class="card-header">
              Mois
            </div>
            <div class="card-body">
              {{freshDeskStatsCounter.month}}
            </div>
            <div class="card-footer text-muted">
              {{freshDeskStatsCounter.lastMonth}}
            </div>
          </div>
        </div>
      </div>-->
      <div class="row mt-3">
        <div class="col">

          <h2>Nouveaux tickets</h2>
          <div class="table-responsive">
            <table class="table table-hover table-dark table-striped">
              <thead>
              <tr>
                <th scope="col">Numéro</th>
                <th scope="col">Heure</th>
                <th scope="col">Origine</th>
                <th scope="col">Sujet</th>
                <th scope="col">Consulter</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let ticket of freshDeskTickets"
                  [ngClass]="(ticket.timediff.minutes >= 9 && ticket.timediff.minutes < 20 && ticket.timediff.hours <= 0) ? 'bg-warning text-dark' : (ticket.timediff.minutes >= 19 || ticket.timediff.hours > 0 ? 'bg-danger' : 'bg-success')">
                <th scope="row">{{ticket.id}}</th>
                <td>{{ticket.createdat.getHours() < 10 ? '0' : ''}}{{ticket.createdat.getHours()}}
                  : {{ticket.createdat.getMinutes() < 10 ? '0' : ''}}{{ticket.createdat.getMinutes()}}</td>
                <td>{{ticket.source}}</td>
                <td>{{ticket.subject}}</td>
                <td>
                  <a href='{{ticket.link}}' target="_blank">
                    <button type="button" class="btn btn-light"><i class="fa fa-eye"></i></button>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
