import {Component, OnInit} from '@angular/core';
import {Hotliner} from '../hotliner';
import {HotlinerService} from '../hotliner.service';
import {startWith, switchMap} from 'rxjs/operators';
import {interval, of} from 'rxjs';
import {HotlinerRingCentral} from '../hotlinerRingCentral';
import {HotlinerStatus} from '../hotlinerStatus';
import {FreshdeskService} from '../freshdesk.service';
import {StatsCounter} from '../stats-counter';
import {RingCentralStatsCounter} from '../ring-central-stats-counter';
import {FreshDeskTicket} from '../freshdeskticket';
import {formatDate, registerLocaleData} from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import {WebsocketService} from '../websocket.service';
import {logger} from 'codelyzer/util/logger';

registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  emptyStatsCounter: StatsCounter = {day: 0, lastDay: 0, lastMonth: 0, lastWeek: 0, month: 0, week: 0};
  freshDeskStatsCounter: StatsCounter = this.emptyStatsCounter;
  ringCentralStatsCounter: RingCentralStatsCounter = {inboundCall: this.emptyStatsCounter, outboundCall: this.emptyStatsCounter};
  freshDeskTickets: FreshDeskTicket[] = [];

  constructor(
    public hotlinerService: HotlinerService,
    private fresDeskService: FreshdeskService,
    private websocketService: WebsocketService) {
  }

  getHotliners(): void {
    this.hotlinerService.getHotliners()
      .then(() => {
        this.subscribePresenceEvent();
        this.websocketService.getReconnectedEvent().subscribe(() => this.hotlinerService.reloadStatus());
      })
      .then(() => {
        of(this.hotlinerService.getHotlinersRingCentral()).subscribe();
      });
  }

  subscribePresenceEvent(): void {
    this.hotlinerService.createPresenceSubscription();
  }

  handleFreshDeskStatsCounter(): void {
    // timer(0, 3 * 60 * 1000)
    //   .pipe(
    //     map(() => this.fresDeskService.getFreshDeskStatsCounter())
    //   )
    //   .subscribe(data => {
    //     this.freshDeskStatsCounter = data;
    //   });
    of(this.fresDeskService.getFreshDeskStatsCounter()).subscribe(data => this.freshDeskStatsCounter = data);
  }

  handleFreshDeskTicketsArray(): void {
    // Every 1 minutes and on page launch
    this.fresDeskService.getFreshDeskLastTickets().subscribe(data => this.freshDeskTickets = data);
    this.runClock();
  }

  private runClock(): void {
    const now = new Date();
    const timeToNextTick = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
    setTimeout(() => {
      this.fresDeskService.getFreshDeskLastTickets().subscribe(data => this.freshDeskTickets = data);
      this.runClock();
    }, timeToNextTick);
  }

  handleRingCentralStatsCounter(): void {
    // timer(0, 3 * 60 * 1000)
    //   .pipe(
    //     map(() => this.hotlinerService.getRingCentralStatsCounter())
    //   )
    //   .subscribe(data => {
    //     this.ringCentralStatsCounter = data;
    //   });
    of(this.hotlinerService.getRingCentralStatsCounter()).subscribe(data => this.ringCentralStatsCounter = data);
  }

  ngOnInit(): void {
    const response = this.hotlinerService.isServiceConnected();
    if (response === true) {
      // this.hotlinerService.handleLogRefreshError();
      this.getHotliners();
      this.handleFreshDeskStatsCounter();
      this.handleFreshDeskTicketsArray();
      this.handleRingCentralStatsCounter();
    } else {
      this.hotlinerService.handleConnection();
    }
  }

}
