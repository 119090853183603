import {Injectable} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {BackResponse} from './BackResponseInterface';
import {Hotliner} from './hotliner';
import {ToastrService} from 'ngx-toastr';
import {HotlinerService} from './hotliner.service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket: Socket;
  socketId: string;

  connected = false;
  private reconnected = new Subject<any>();

  constructor() {
    this.socket = io(environment.websocketServer);

    this.socket.on('connect', () => {
      if (this.connected) { // if was connected, emit reconnected event
        this.socket.emit('reconnected', this.socketId, () => {
          console.log('reconnected');
          this.socketId = this.socket.id;
          this.reconnected.next();
        });
      }
    });
  }

  login(username, password, callback): void {
    this.socket.emit('login', username, password, (response: BackResponse) => {
      if (response.status === 'success') {
        this.connected = true;
        this.socketId = this.socket.id;
      }
      callback(response);
    });
  }

  // for debug only
  /*test(): void {
    this.socket.disconnect();
    this.socket.connect();
  }*/

  getHotlinersStatus(callback): void {
    this.socket.emit('getHotlinersStatus', (response: BackResponse) => {
      callback(response);
    });
  }

  getHotlinersInfo(callback): void {
    this.socket.emit('getHotlinersInfo', (response: BackResponse) => {
      callback(response);
    });
  }

  subscribe(hotliners: Hotliner[], callback): void {
    this.socket.emit('subscribe', hotliners, (response: BackResponse) => {
      if (response.status === 'success') {
        this.socket.on('notification', callback);
      } else {
        // show notification alert
        console.log(response.content);
        console.log('not subscribed');
      }
    });
  }

  getSocketId(): string {
    return this.socketId;
  }

  isConnected(): boolean {
    return this.connected;
  }

  getReconnectedEvent(): Observable<any> {
    return this.reconnected.asObservable();
  }
}
