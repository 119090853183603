interface DateDifference {
  seconds: number;
  minutes: number;
  hours: number;
  days: number
}

export class FreshDeskTicket {

  // tslint:disable-next-line:max-line-length
  constructor(id: number, source: string, createdat: Date, priority: string, subject: string, status: string, responderid: string, link: string) {
    this.id = id;
    this.source = this.handleSourceString(source);
    this.createdat = createdat;
    this.timediff = FreshDeskTicket.dateDiff(createdat, new Date());
    this.priority = this.handlePriorityString(priority);
    this.subject = subject;
    this.status = this.handleStatusString(status);
    this.responderid = responderid;
    this.link = link;
  }
  id: number;
  source: string;
  createdat: Date;
  timediff: DateDifference;
  priority: string;
  subject: string;
  status: string;
  responderid: string;
  link: string;

  private sourceTranslation: Map<number, string> = new Map<number, string>(
    // Email	1
    // Portal	2
    // Phone	3
    // Chat	7
    // Feedback Widget	9
    // Outbound Email	10
    [
      [1, 'Mail'],
      [2, 'Portail'],
      [3, 'Téléphone'],
      [7, 'Chat'],
      [9, 'Feedback Widget'],
      [10, 'Email sortant']
    ]
  );

  private priorityTranslation: Map<number, string> = new Map<number, string>(
    // Low	1
    // Medium	2
    // High	3
    // Urgent	4
    [
      [1, 'Basse'],
      [2, 'Moyenne'],
      [3, 'Haute'],
      [4, 'Urgente']
    ]
  );

  private statusTranslation: Map<number, string> = new Map<number, string>(
    // Open	2
    // Pending	3
    // Resolved	4
    // Closed	5
    [
      [2, 'Ouvert'],
      [3, 'En attente'],
      [4, 'Résolu'],
      [5, 'Fermé']
    ]);

  private static dateDiff(date1: Date, date2: Date): DateDifference {
    const diff: DateDifference = {
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    };

    let tmp = date2.getTime() - date1.getTime();

    tmp = Math.floor(tmp / 1000);
    diff.seconds = tmp % 60;

    tmp = Math.floor((tmp - diff.seconds) / 60);
    diff.minutes = tmp % 60;

    tmp = Math.floor((tmp - diff.minutes) / 60);
    diff.hours = tmp % 24;

    tmp = Math.floor((tmp - diff.hours) / 24);
    diff.days = tmp;

    return diff;
  }

  handleSourceString(origin): string {
    if (this.sourceTranslation.has(origin)) {
      return this.sourceTranslation.get(origin);
    } else {
      return origin;
    }
  }

  handlePriorityString(origin): string {
    if (this.priorityTranslation.has(origin)) {
      return this.priorityTranslation.get(origin);
    } else {
      return origin;
    }
  }

  handleStatusString(origin): string {
    if (this.statusTranslation.has(origin)) {
      return this.statusTranslation.get(origin);
    } else {
      return origin;
    }
  }
}
