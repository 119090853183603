import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
// For this import : use the one with a @ following the documentation
// Import the @types for Pubnum from NPM
// use the flag "allowSyntheticDefaultImports": true to bypass errors on compiler
import {Hotliner} from './hotliner';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {HotlinerStatus} from './hotlinerStatus';
import {HotlinerActiveCall} from './hotlinerActiveCall';
import {StatsCounter} from './stats-counter';
import moment from 'moment';
import {RingCentralStatsCounter} from './ring-central-stats-counter';
import {WebsocketService} from './websocket.service';
import {BackResponse} from './BackResponseInterface';
import {ToastrService} from 'ngx-toastr';
import {HotlinerRingCentral} from './hotlinerRingCentral';
import {formatDate} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class HotlinerService {

  private hotlinersRingCentral: HotlinerRingCentral[] = [];

  tabTranslation: Array<{ name: string, translation: string }> = [
    // Statut de présence défini == prensenceStatus
    {name: 'Busy', translation: 'Occupé'},
    {name: 'Available', translation: 'Disponible'},
    {name: 'Offline', translation: 'Déconnecté'},
    // Statut dérangement == dndStatus
    {name: 'TakeAllCalls', translation: 'Prends tous les appels'},
    {name: 'DoNotAcceptAnyCalls', translation: 'Ne prends aucun appel'},
    {name: 'DoNotAcceptDepartmentCalls', translation: 'Ne prends aucun appel'},
    {name: 'TakeDepartmentCallsOnly', translation: 'Prends uniquement des appels de la file d\'attente'},
    // Statut téléphonique == telephonyStatus
    {name: 'NoCall', translation: 'Pas d\'appel'},
    {name: 'CallConnected', translation: 'Appel en cours'},
    {name: 'Ringing', translation: 'Sonnerie'},
    {name: 'OnHold', translation: 'En attente'},
    {name: 'ParkedCall', translation: 'Appel en attente'},
    // Undefined status
    {name: undefined, translation: '...'}
  ];

  hotlinersInfo: Hotliner[];

  constructor(private router: Router, private websocketService: WebsocketService, private toastr: ToastrService) {
  }

  isServiceConnected(): boolean {
    return this.websocketService.isConnected();
  }

  handleConnection(): any {
    if (!this.websocketService.isConnected()) {
      return this.router.navigateByUrl('/login');
    }
  }

  handleLogout(): void {
    window.location.reload();
  }

  getHotlinerStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.websocketService.getHotlinersStatus((response: BackResponse) => {
        response.status === 'success' ? resolve(response.content) : reject();
      });
    }).catch(() => {
      this.toastr.error('Une erreur s\'est produite lors de la récupération du statut des hotliners', 'Internal Error');
    });
  }

  getHotlinersInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.websocketService.getHotlinersInfo((response: BackResponse) => {
        response.status === 'success' ? resolve(response.content) : reject();
      });
    }).catch(() => {
      this.toastr.error('Une erreur s\'est produite lors de la récupération des infos des hotliners', 'Internal Error');
    });
  }

  reloadStatus(): void {
    if (this.hotlinersRingCentral.length !== 0) {
      this.createPresenceSubscription(); // resubscribe

      // get hotliners status
      this.getHotlinerStatus().then((hotlinersStatus) => {
        // re-update hotliners status after a bit of time
        this.hotlinersRingCentral.forEach((hotliner) => {
          const index = hotlinersStatus.map((item) => item.id).indexOf(hotliner.id); // get the associated status

          // update status
          this.handlePresenceEventMessage({body: hotlinersStatus[index]});
        });
        console.log('reloaded');
      });
    } else {
      console.log('not hotliner fetched');
    }
  }

  handlePresenceEventMessage(msg): void {
    if (msg !== null && msg !== undefined) {
      const hotlinerStatusItem: HotlinerStatus =
        {
          id: msg.body.extensionId,
          realStatus: msg.body.userStatus,
          definedStatus: msg.body.presenceStatus,
          telephonyStatus: msg.body.telephonyStatus,
          dndStatus: msg.body.dndStatus,
          hotlinerActiveCalls: []
        };
      if (msg.body.hasOwnProperty('activeCalls')) {
        for (const activeCallItem of msg.body.activeCalls) {
          const hotlinerStatusActiveCallToAdd: HotlinerActiveCall =
            {
              direction: activeCallItem.direction,
              from: activeCallItem.from,
              to: activeCallItem.to,
              startTime: activeCallItem.startTime,
              telephonyStatusActiveCall: activeCallItem.telephonyStatus
            };
          hotlinerStatusItem.hotlinerActiveCalls.push(hotlinerStatusActiveCallToAdd);
        }
      }
      this.updatePresence(hotlinerStatusItem);
    }
  }

  createPresenceSubscription(): void {
    // Get subscription from cache - if not present make a new one
    // Intend to share subscription between tabs

    this.websocketService.subscribe(this.hotlinersInfo, response => this.handlePresenceEventMessage(response.content));
  }

  updatePresence(eventPresence: HotlinerStatus): void {
    const elementPos = this.hotlinersRingCentral.map((hotlinerItem) => hotlinerItem.id).indexOf(eventPresence.id);
    if (elementPos !== -1) {
      this.translateHotlinerStatus(eventPresence);
      this.hotlinersRingCentral[elementPos].hotlinerStatus = eventPresence;
      this.hotlinersRingCentral[elementPos].lastTimeUpdated = '' + formatDate(new Date(), 'HH:mm', 'fr-FR');
    }
  }

  /*handleLogRefreshError(): void {
    this.rcsdk.platform().on(this.rcsdk.platform().events.refreshError, (e) => {
      console.log('Handling refresh log error : ' + e);
      this.handleConnection();
    });
  }*/

  /*handleRingCentralCallLogRequest(pageIndex: number = 1, dataFromPreviousCall?: any[]): any {
    if (pageIndex === 1) {
      dataFromPreviousCall = [];
    }

    console.log('called !');

    const queryParamsStats = {
      direction: ['Inbound', 'Outbound'],
      type: ['Voice'],
      view: 'Simple',
      // Local format UTC+1 converted in UTC in the API call
      // API returns in UTC format
      // We take the month and the month before to compare the evolution
      dateFrom: moment().startOf('month').subtract(1, 'month').format(),
      dateTo: moment().format(),
      page: pageIndex,
      perPage: 250,
    };

    this.rcsdk.platform().get('/restapi/v1.0/account/~/call-log', queryParamsStats)
      .then((statsResponse) => {
        return statsResponse.json();
      })
      .then((jsonResponse) => {
        dataFromPreviousCall.push(jsonResponse);
        console.log(dataFromPreviousCall);
        if (jsonResponse.hasOwnProperty('navigation') && jsonResponse.navigation.hasOwnProperty('nextPage')) {
          pageIndex = pageIndex + 1;
          console.log('calling next page ' + pageIndex);
          this.handleRingCentralCallLogRequest(pageIndex, dataFromPreviousCall);
        }
      })
      .catch((error) => {
        console.log('Error Getting RingCentral stats : ' + error);
      });

  }*/

  // TODO handle multipage
  getRingCentralStatsCounter(): RingCentralStatsCounter {
    const emptyStatsCounter: StatsCounter = {day: 0, lastDay: 0, lastMonth: 0, lastWeek: 0, month: 0, week: 0};

    return {
      inboundCall: emptyStatsCounter,
      outboundCall: emptyStatsCounter
    };

    // this.handleRingCentralCallLogRequest();
  }

  addHotliner(hotliner: HotlinerRingCentral): void {
    this.hotlinersRingCentral.push(hotliner);
  }

  getHotlinersRingCentral(): HotlinerRingCentral[] {
    return this.hotlinersRingCentral;
  }

  getHotliners(): Promise<any> {
    return Promise.all([this.getHotlinersInfo(), this.getHotlinerStatus()])
      .then(([hotlinersInfo, hotlinersStatus]) => {
        // Create hotlinerstatusRingCentral object with the two arrays received
        this.hotlinersInfo = hotlinersInfo;

        for (const hotlinerInfoItem of hotlinersInfo) {
          const foundHotlinerStatusItem = hotlinersStatus.find(element => element.id === hotlinerInfoItem.id);

          if (foundHotlinerStatusItem) {
            this.translateHotlinerStatus(foundHotlinerStatusItem);

            const newItemToPush: HotlinerRingCentral = {
              id: hotlinerInfoItem.id,
              hotlinerInfo: hotlinerInfoItem,
              hotlinerStatus: foundHotlinerStatusItem,
              lastTimeUpdated: '' + formatDate(new Date(), 'HH:mm', 'fr-FR')
            };
            this.addHotliner(newItemToPush);
          }
        }
      });
  }

  private translateHotlinerStatus(item: HotlinerStatus): void {
    item.dndStatus = this.tabTranslation
      .find(status => status.name === item.dndStatus).translation;
    item.definedStatus = this.tabTranslation
      .find(status => status.name === item.definedStatus).translation;
    item.telephonyStatus = this.tabTranslation
      .find(status => status.name === item.telephonyStatus).translation;
  }

}
