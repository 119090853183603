<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-10 py-5">
      <form action="" (ngSubmit)="submit()" [formGroup]="form">
        <div class="form-group row">
          <label for="username" class="col-md-4 col-form-label text-md-right">numéro de téléphone</label>

          <div class="col-md-6 input-group">

            <div class="input-group-prepend">
              <span class="input-group-text">+33</span>
            </div>

            <input id="username" type="tel" class="form-control"
                   [ngClass]="username.invalid && (username.dirty || username.touched) ? 'is-invalid' : ''"
                   formControlName="username" required
                   autofocus>

            <div class="invalid-feedback">
              adresse email ou numéro de téléphone invalide
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="password" class="col-md-4 col-form-label text-md-right">Mot de passe</label>

          <div class="col-md-6 input-group">
            <input id="password" [type]="visible ? 'text' : 'password'" class="form-control"
                   [ngClass]="password.invalid && (password.dirty || password.touched) ? 'is-invalid' : ''"
                   formControlName="password"
                   required autofocus>

            <div class="input-group-append" (click)="togglePassword()">
              <span role="button" title="viewer-password" id="passwordBtn" class="input-group-text">
                <i class="fa fa-fw" [ngClass]="visible ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"></i>
              </span>
            </div>

            <div class="invalid-feedback">
              Mot de passe invalide
            </div>
          </div>
        </div>

        <div class="form-group row mb-4">
          <div class="col-md-6 offset-md-4">
            <button type="submit" class="btn btn-primary">
              Se connecter
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
