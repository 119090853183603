import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {StatsCounter} from './stats-counter';
import {Observable} from 'rxjs';
import {FreshDeskTicket} from './freshdeskticket';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {WebsocketService} from './websocket.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class FreshdeskService {

  constructor(private httpclient: HttpClient, private websocketService: WebsocketService, private toastr: ToastrService) {
  }

  getFreshDeskStatsCounter(): StatsCounter {
    return {lastDay: 0, lastMonth: 0, lastWeek: 0, day: 0, week: 0, month: 0};
  }

  getFreshDeskLastTickets(acc = 0): Observable<FreshDeskTicket[]> {
    return this.httpclient.get(environment.freshdeskMiddlewareURL + '/tickets', {
      headers: {
        'XX-SOCKET-ID': this.websocketService.getSocketId()
      }
    })
      .pipe(
        map(
          (response: any[]) => {
            // Filter only open and unassigned tickets
            return response.filter(ticket => ticket.responder_id === null).map((ticket: any) =>
              new FreshDeskTicket(
                ticket.id,
                ticket.source,
                new Date(ticket.created_at),
                ticket.priority,
                ticket.subject,
                ticket.status,
                ticket.responder_id,
                'https://hubtechfrance.freshdesk.com/a/tickets/' + ticket.id));
          }
        ),
        catchError(err => {
          console.log(err);
          if (acc < 3) {
            this.toastr.error('récupération de ticket échoué, ressaie...', 'Server error');
            return this.getFreshDeskLastTickets(acc + 1);
          }
          this.toastr.error('Problème de récupération de ticket', 'Server error');
          console.log(err.message);
          return [];
        })
      );
  }
}
