import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {WebsocketService} from '../websocket.service';
import {BackResponse} from '../BackResponseInterface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})

export class ConnectComponent implements OnInit {

  form: FormGroup;
  visible = false;

  constructor(private websocketService: WebsocketService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern('\\+?[0-9]{9,12}')]),
      password: new FormControl('', [Validators.required])
    });
  }

  async submit(): Promise<any> {
    if (this.form.valid) {

      if (this.username.value.startsWith('0')) {
        this.username.setValue(this.username.value.replace('0', '+33'));
      } else if (!this.username.value.startsWith('+33') && !this.username.value.startsWith('+44')) {
        this.username.setValue('+33' + this.username.value);
      }

      const resp: BackResponse = await new Promise((resolve) => {
        this.websocketService.login(this.username.value, this.password.value, (response: BackResponse) => {
          return resolve(response);
        });
      });

      if (resp.status === 'success') {
        this.toastr.success('Vous êtes maintenant connecté !', 'Connecté');
        await this.router.navigateByUrl('/');
      } else {
        this.form.controls.username.setErrors({ incorrect: true });
        this.form.controls.password.setErrors({ incorrect: true });
        return false;
      }
    }
  }

  togglePassword(): void {
    this.visible = !this.visible;
  }

  get username(): AbstractControl {
    return this.form.get('username');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

}
