import {Component} from '@angular/core';
import {HotlinerService} from './hotliner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HubtechDashboardFront';

  constructor(private hotlinerService: HotlinerService) {
  }

  reloadHotliners(): void {
    this.hotlinerService.reloadStatus();
  }

  logoutRcApi(): void {
    this.hotlinerService.handleLogout();
  }

}

